<template>
  <div
    class="w-full py-6 px-5 flex justify-between items-center bg-Light-01-Default shadow-mini-basket"
    :class="{ 'fixed top-0 z-10': isScrolledAboveThreshold }"
  >
    <div>
      <div
        class="flex items-center space-x-2"
      >
        <span
          class="font-poppins font-semibold text-2xs text-Light-01-Text-Primary leading-6"
        >
          {{ getTranslation(vehicleName) }}
        </span>
        <ul
          class="list-disc list-inside font-poppins font-semibold text-2xs text-Light-01-Text-Primary leading-6"
        >
          <li>
            {{ getTranslation(subscriptionPlanLabel) }}
          </li>
        </ul>
      </div>
      <div>
        <p
          v-for="product of products"
          :key="product.id"
          class="font-poppins text-3xs text-Light-02-Text-Secondary leading-5"
        >
          + {{ getTranslation(product.label) }}
        </p>
      </div>
    </div>
    <div>
      <p
        class="font-poppins text-2xs text-center text-16-Agency-500 leading-6"
      >
        {{ currencySymbol }}{{ totalPrice }} / {{ computedPlanBillingFrequency }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStickyHelper } from '~/composables/sticky-helper'
import { useTranslations } from '~/composables/translations'
import { useCountryConfig } from '~/composables/countryConfig'
import type { Product } from '~/types/Product'

interface Props {
  vehicleName?: Record<string, string>
  subscriptionPlanLabel?: Record<string, string>
  subscriptionBillingFrequency?: 'weekly' | 'monthly'
  totalPrice?: string
  products: Array<Product> | null
}

const props = defineProps<Props>()

interface Emits {
  (e: 'needsPadding', value: boolean): void
}

const emit = defineEmits<Emits>()

const { t } = useI18n()
const { getTranslation } = useTranslations()
const { currencySymbol } = useCountryConfig()
const { isScrolledAboveThreshold } = useStickyHelper(55)

watchEffect(() => {
  emit('needsPadding', isScrolledAboveThreshold.value)
})

const computedPlanBillingFrequency = computed((): string => {
  if (!props.subscriptionBillingFrequency) {
    return '-'
  }

  return t(`general.${props.subscriptionBillingFrequency.substring(0, props.subscriptionBillingFrequency.length - 2)}`)
})
</script>
