<template>
  <section>
    <MiniBasket
      v-if="selectedSubscriptionPlan"
      :vehicle-name="selectedVehicle?.label"
      :subscription-plan-label="selectedSubscriptionPlan?.label"
      :subscription-billing-frequency="selectedSubscriptionPlan?.billing_frequency"
      :total-price="total"
      :products="selectedProducts"
      @needs-padding="emit('needsPadding', $event)"
    />
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useSelectionStore } from '~/store/selection-store'
import { useTranslations } from '~/composables/translations'

const selectionStore = useSelectionStore()
const {
  selectedSubscriptionPlan,
  selectedVehicle,
  selectedProducts,
  total
} = storeToRefs(selectionStore)

interface Emits {
  (e: 'needsPadding', value: boolean): void
}

const emit = defineEmits<Emits>()
</script>
